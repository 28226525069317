/* ==========================================================================
   Card
   ========================================================================== */

.card-info {
    &.active,
    &:hover {
        border-color: $primary;
        background-color: $primary;
        cursor: pointer;
        
        * {
            color: $white;
        }
    }
}